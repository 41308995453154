import React from 'react';
import UpdateBasicProfile from '../../../components/ProfilePage/UpdateBasicProfile';
import UserLayout from '../../../layouts/UserLayout';



const UpdateBasicProfilePage = () => {
        
return (
        <UserLayout>
           <UpdateBasicProfile />
        </UserLayout>
);
};

export default UpdateBasicProfilePage;