import RequestedForNewService from "../../components/services/RequestedForNewService";
import AdminLayout from "../../layouts/AdminLayout";

const RequestedForNewServicePage = ()=>{
    return(
        <AdminLayout>
            <RequestedForNewService />
        </AdminLayout>
    );
}
export default RequestedForNewServicePage;